import $ from 'jquery';
import Coupon from "./coupons";

function buyModalForm() {
    document.querySelectorAll('.buy-modal').forEach((modal) => {
        let couponForm = null;
        const buyForm = modal.querySelector('form');

        const haveCouponField = buyForm.querySelector('input[name=have_coupon]');
        const couponField = buyForm.querySelector('input[name=coupon]');
        const couponCheckButton = buyForm.querySelector('.check-coupon-btn');

        // если есть флажок наличия купона, значит есть и все остальное
        if (haveCouponField){
            // создаем экземпляр класса для каждой формы, если в ней есть возможность ввода купона
            couponForm = new Coupon(buyForm);

            haveCouponField.addEventListener('click', (event) => {
                if (event.target.checked){
                    couponForm.show();
                } else {
                    couponForm.clear();
                    couponForm.hide();
                }
            });

            couponCheckButton.addEventListener('click', (event) => {
                couponForm.validate(couponField.value);
            });
        }

        $(modal).on('hidden.bs.modal', function() {
            const emailField = buyForm.querySelector('input[name=email]');
            if (emailField){
                emailField.value = '';
                emailField.focus();
            }
            if (haveCouponField){
                haveCouponField.checked = false;
                couponForm.clear();
                couponForm.hide();
            }
        });

        $(modal).on('show.bs.modal', function() {
            if (couponForm){
                const couponList = buyForm.querySelector('.coupon-list');
                if (couponList == undefined){
                    couponForm.getList();
                }
            }
        });
    })
};

export default buyModalForm;

