// https://f3oall.github.io/awesome-notifications/
import AWN from "awesome-notifications"

const duration = window.debug ? 10000 : 1500;

let notifierGlobalOptions = {
    icons: {
        enabled: false,
    },
    labels:{
        success: gettext('Успешно'),
        alert: gettext('Ошибка'),
    },
    position: 'top-right',
    durations:{
        global: duration,
    }
};
let notifier = new AWN(notifierGlobalOptions);

export default notifier;
