import * as axios from "axios";
import { projectUrl } from '../config';

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: projectUrl + '/' + window.lang + '/api/',
});

export const CancelToken = axios.CancelToken;

export default axiosInstance;