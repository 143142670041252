import notifier from './notifier';
import axiosInstance from '../_react/_api/config';
import Cookie from './cookie';

const csrftoken = new Cookie('csrftoken');

const favoriteCounterElement = document.getElementById('collection-counter');
const url = 'collection/';

const insertEventListener = (event) => {
  event.preventDefault();
  const button = event.currentTarget;
  const offer = button.getAttribute('data-offer');
  const short = button.getAttribute('data-short');

  axiosInstance.post(url,
    {'offer': offer},
    {headers: {'X-CSRFToken': csrftoken.get()}})
    .then(response => {
      if (response.status === 201) {
        notifier.success(gettext('Аранжировка добавлена в коллекцию'));

        favoriteCounterElement.classList.remove('badge-primary');
        favoriteCounterElement.classList.add('badge-orange');
        favoriteCounterElement.innerHTML = parseInt(favoriteCounterElement.innerHTML) + 1;

        button.classList.remove('card-favorite__off');
        button.classList.add('card-favorite__on');
        button.classList.remove('btn-orange');
        button.classList.add('btn-outline-light');
        button.setAttribute('title', gettext('Удалить из коллекции'));
        button.innerHTML = '<i class="icon icon-cancel"></i> ' +
          (short ? gettext('Из коллекции') : gettext('Удалить из коллекции'));
        button.addEventListener('click', removeEventListener, {once: true});

        //  GuitarOnly
        dataLayer.push({'event': 'addFavorite'});
      }
    })
    .catch(error => console.error(error));
};


const removeEventListener = (event) => {
  event.preventDefault();
  const button = event.currentTarget;
  const offer = button.getAttribute('data-offer');
  const short = button.getAttribute('data-short');

  axiosInstance.delete(url,
      {params: {'offers': offer}, headers: {'X-CSRFToken': csrftoken.get()}}
    )
    .then(response => {
      // const data = response.data;
      if (response.status === 204) {
        notifier.success(gettext('Аранжировка удалена из коллекции'));
        let counter = parseInt(favoriteCounterElement.innerHTML) - 1;
        if (counter === 0) {
          favoriteCounterElement.classList.remove('badge-orange');
          favoriteCounterElement.classList.add('badge-primary');
        }
        favoriteCounterElement.innerHTML = counter;

        button.classList.remove('card-favorite__on');
        button.classList.add('card-favorite__off');
        button.classList.remove('btn-outline-light');
        button.classList.remove('btn-outline-dark');
        button.classList.add('btn-orange');
        button.setAttribute('title', gettext('Добавить в коллекцию'));
        button.innerHTML = '<i class="icon icon-plus"></i> ' +
          (short ? gettext('В коллекцию') : gettext('Добавить в коллекцию'));
        button.addEventListener('click', insertEventListener, {once: true});
      }
    })
    .catch(error => console.error(error));
};


export function addFavorite() {
  document.querySelectorAll('.card-favorite__off').forEach(function (button) {
    button.addEventListener('click', insertEventListener, {once: true});
  });
};

export function removeFavorite() {
  document.querySelectorAll('.card-favorite__on').forEach(function (button) {
    const offer = button.getAttribute('data-offer');
    const parent = button.parentNode;
    button.addEventListener('click', removeEventListener, {once: true});
  });
};
