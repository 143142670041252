import axiosInstance from '../_react/_api/config';

export function loadBuyButton(){
  let priceBlocks = document.querySelectorAll('.item-shop-buy-button');

  priceBlocks.forEach(element => {
    let offer_id = parseInt(element.getAttribute('data-offer'));
    const url = `shop/load-buy-button/${offer_id}/`;
    axiosInstance.get(url)
      .then(response => {
        if (response.status === 200){
          let btn = element.querySelector('.buy-now-dropdown-item');
          if (!response.data.can_buy_now){
            btn.setAttribute('disabled', 'disabled');
          }
          if (!response.data.show_modal){
            btn.removeAttribute('data-toggle').removeAttribute('data-target');
            btn.addEventListener('click', function(e){
              btn.parentNode.submit();
            });
          }
          if (response.data.user_bought){
            element.innerHTML = '<a href="' + response.data.file_url + '"' +
              ' class="btn btn-xs btn-outline-dark btn_width_100 text-uppercase">' +
              gettext('Скачать файлы') + '</a>';
          }
        }
        console.log(response);
      })
      .catch(error => console.error(error));
  });
}