import React from 'react';

export const sentryDns = 'https://2f835c1d11ca4d708d752f0b7de9cea5@sentry.pianosolo.me//9';
export const placeholderAmount = 5;
export const perPage = 10;
export const projectStart = 2018;
export const projectName = 'GuitarSolo.info';
export const shortProjectName = 'GuitarSolo';
export const projectUrl = document.location.origin;
export const maxTagsAmount = 3;
export const moderatorMaxTagsAmount = 10;

export const MIN_PRICE_USER_PAYMENT = 2.00
export const DISCOUNTS_BY_AMOUNT = { 3: 5, 5: 7, 10: 10, 20: 20 };

export const YANDEX = 'yandex';
export const PAYPAL = 'paypal';
// export const PAYSSION = 'payssion';
// export const OTHER = 'other';

export const ARRANGEMENT_TYPE_SLUG = 'guitar-sheets-tabs';
export const LESSON_TYPE_SLUG = 'guitar-lessons';
export const EDUCATION_TYPE_SLUG = 'guitar-training';
export const CABINET_SLUG = 'my';

export const SHOP_TYPES = {
    [ARRANGEMENT_TYPE_SLUG]: {
      'title': gettext('Аранжировки'),
      'url': 'shop-arrangements/',
      'infoName': 'shop_arrangements_form:',
      'createMessage': gettext('Аранжировка успешно создана. Она появится на сайте после прохождения модерации.'),
      'updateMessage': gettext('Изменения успешно внесены. Аранжировка появится на сайте после прохождения модерации.'),
    },
    [LESSON_TYPE_SLUG]: {
      'title': gettext('СкороНавыки'),
      'url': 'shop-lessons/',
      // 'infoName': 'shop_lessons_form:',
      'createMessage': gettext('Урок успешно создан. Он появится на сайте после прохождения модерации.'),
      'updateMessage': gettext('Изменения успешно внесены. Урок появится на сайте после прохождения модерации.'),
    },
    [EDUCATION_TYPE_SLUG]: {
      'title': gettext('Курсы и Уроки'),
      'url': 'shop-lessons/',
      // 'infoName': 'shop_lessons_form:',
      'createMessage': gettext('Урок успешно создан. Он появится на сайте после прохождения модерации.'),
      'updateMessage': gettext('Изменения успешно внесены. Урок появится на сайте после прохождения модерации.'),
    },
}

export const DEPARTMENTS = {
  'login': { title: gettext('Авторизация'), menu_exclude: true },
  'moderator': {
    title: gettext('Я Модератор'),
    is_moderator: true,
    counter: {
      key: 'moderatorCounter',
      variant: 'danger',
      title: gettext('Количество неопубликованных товаров.'),
    },
    children: {
      [ARRANGEMENT_TYPE_SLUG]: {
        title: gettext('Аранжировки'),
        children: {
          'update': {
            title: gettext('Редактировать Аранжировку'),
          }
        },
        counter: {
          key: 'moderatorArrangementCounter',
          variant: 'danger',
          title: gettext('Количество неопубликованных аранжировок.'),
        },
      },
      [LESSON_TYPE_SLUG]: {
        title: gettext('СкороНавыки'),
        children: {
          'update': {
            title: gettext('Редактировать Урок'),
          }
        },
        counter: {
          key: 'moderatorLessonsCounter',
          variant: 'danger',
          title: gettext('Количество неопубликованных уроков.'),
        },
      },
    }
  },
  'home': { title: gettext('Моя Страница') },
  'notifications': {
    title: gettext('Уведомления'),
    counter: {
      key: 'notificationsCounter',
      variant: 'primary',
      title: gettext('Количество неочитанных уведомлений.'),
    },
  },
  'balance': {
    title: gettext('Мой Баланс'),
    children: {
      'stat/actions': {
        title: gettext('Статистика по подписчикам'),
        old_version: true,
      },
      'accounts/transfers': {
        title: gettext('Мои доходы'),
        old_version: true,
      },
      'addfunds': {
        title: gettext('Пополнить Баланс'),
        old_version: true,
      },
      'history': {
        title: gettext('История Пополнений'),
        old_version: true,
      },
      'referrals': {
        title: gettext('Мои Рефералы'),
        old_version: true,
      },
    }
  },
  'purchases': {
    title: gettext('Мои Покупки'),
    children: {
      // [ARRANGEMENT_TYPE_SLUG]: {
      //   title: gettext('Аранжировки'),
      // },
      [LESSON_TYPE_SLUG]: {
        title: gettext('СкороНавыки'),
      },
      [EDUCATION_TYPE_SLUG]: {
        title: gettext('Курсы и Уроки'),
      },
    }
  },
  'collection': {
    title: gettext('Моя Коллекция'),
    children:{
      [ARRANGEMENT_TYPE_SLUG]: {
        title: gettext('Аранжировки'),
        counter: {
          key: 'collectionsCounter',
          variant: 'primary',
          title: gettext('Количество аранжировок в коллекции'),
        },
      },
      'playlists': {
        title: gettext('Мои Плейлисты'),
        counter: {
          key: 'playlistsCounter',
          variant: 'primary',
          title: gettext('Количество плейлистов в коллекции'),
        },
      },
      'authors': {
        title: gettext('Авторы'),
        counter: {
          key: 'authorsCounter',
          variant: 'primary',
          title: gettext('Количество авторов в коллекции'),
        },
      },
      'genres': {
        title: gettext('Жанры'),
        counter: {
          key: 'genresCounter',
          variant: 'primary',
          title: gettext('Количество жанров в коллекции'),
        },
      },
    }
  },
  // 'favorites': { title: gettext('Моё Избранное') },
  // 'subscribes': { title: gettext('Мои Подписки') },
  'coupons': {
    title: gettext('Мои Промокоды'),
    counter: {
      key: 'couponsCounter',
      variant: 'primary',
      title: gettext('Количество неиспользованных промокодов.'),
    },
  },
  'cart': {
    title: gettext('Моя Корзина'),
    counter: {
      key: 'cartCounter',
      variant: 'primary',
      title: gettext('Количество товаров в корзине.'),
    },
  },
  'content': {
    title: gettext('Мой Контент'),
    children: {
      [ARRANGEMENT_TYPE_SLUG]: {
        title: gettext('Аранжировки'),
        create: true,
        counter: {
          key: 'arrangementCounter',
          variant: 'danger',
          title: gettext('Количество неопубликованных аранжировок.'),
        },
        children: {
          'create': {
            title: gettext('Новая Аранжировка'),
            old_version: true,
          },
          'update': {
            title: gettext('Редактировать Аранжировку'),
            old_version: true,
          },
          // 'update-price': {
          // title: gettext('Изменить цены'),
          // old_version: true,
          // },
          // 'sale': {
          //     title: gettext('Запустить распродажу'),
          //     old_version: true,
          // },
        }
      },
      'guitar-sheets-tabs/s/update-price': {
        title: gettext('Изменить цены'),
        old_version: true,
      },
      'guitar-sheets-tabs/s/sale': {
            title: gettext('Запустить распродажу'),
            old_version: true,
      },

      [LESSON_TYPE_SLUG]: {
        title: gettext('СкороНавыки'),
        is_staff: true,
        create: true,
        counter: {
          key: 'lessonsCounter',
          variant: 'danger',
          title: gettext('Количество неопубликованных уроков.'),
        },
        children: {
          'create': {
            title: gettext('Новый Урок'),
          },
          'update': {
            title: gettext('Редактировать Урок'),
          }
        }
      },
      [EDUCATION_TYPE_SLUG]: {
        title: gettext('Курсы и Уроки'),
        is_teacher: true,
      },
      'articles': {
        title: gettext('Статьи'),
        old_version: true,
      },
      'guitar': {
        title: gettext('Обзоры Гитар'),
        old_version: true,
      },
      'aphorisms': {
        title: gettext('Цитаты и Фразы'),
        create: true,
        counter: {
          key: 'aphorismsCounter',
          variant: 'danger',
          title: gettext('Количество неопубликованных цитат/фраз.'),
        },
        children:{
          'create': {
            title: gettext('Новая Цитата, Фраза'),
          },
        }
      },
    }
  },
  'messages/inbox': {
    title: gettext('Личные Сообщения'),
    old_version: true,
  },
  'comments': {
    title: gettext('Комментарии, Ответы'),
    old_version: true,
  },
  'settings': {
    title: gettext('Настройки'),
    children:{
      'profile': { title: gettext('Настройки профиля') },
      'sales-agree': { title: gettext('Настройки продаж') },
      'password-change': { title: gettext('Сменить пароль') },
      'delete-card': {
        title: gettext('Отменить подписку'),
        yandex_saved_payment: true,
      },
      'delete-subscription': {
        title: gettext('Отменить подписку'),
        paypal_subscription: true,
      },
      'payment-data': { title: gettext('Мои Реквизиты'), create: true },
    }
  },
}

export const getDepartmentChildrenAsLinksArray = (params) => {
  let tree = DEPARTMENTS;
  let list = [];
  let url = '/';

  for (let param of params){
    url = url + param +'/';
    try {
      list = [...list, { title: tree[param]['title'], url }];
    } catch (e){
      break;
    }

    if (tree[param]['children']){
      tree = tree[param]['children'];
      continue;
    }
  }
  return list;
}

export const getDepartmentLastChild = (params) => {
  let tree = DEPARTMENTS;
  let child;

  try{
    for (let param of params){
      child = tree[param];

      if (child['children']){
        tree = child['children'];
        continue;
      }
    }
  } catch (e){

  }
  return child;
}

export const getDepartmentChildren = (param) => {
  return DEPARTMENTS[param]['children'];
}

export const getDepartmentForMenu = (is_moderator, is_staff) => {
  let list = [];
  for (const [key, value] of Object.entries(DEPARTMENTS)) {
    if (!value.menu_exclude){
      let menu = {...DEPARTMENTS[key], key};
      if (value.is_moderator) {
        if (is_moderator) list.push(menu);
        continue;
      }
      if (value.is_staff) {
        if (is_staff) list.push(menu);
        continue;
      }
      list.push(menu);
    }
  }
  return list;
}
