function visibilityToggle(button, visible=false) {
    if (visible){
        button.classList.add('footer__go-top_visible');
        button.classList.remove('footer__go-top_invisible');
    } else {
        button.classList.remove('footer__go-top_visible');
        button.classList.add('footer__go-top_invisible');
    }
}

function backToTop() {
    const backToTopButton = document.querySelector('.footer__go-top');

    backToTopButton.addEventListener('click', (event) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });

    window.addEventListener('scroll', (event) => {
        if (window.scrollY > 250){
            visibilityToggle(backToTopButton, true);
        } else {
            visibilityToggle(backToTopButton, false);
        }
    });
};

export default backToTop;