import sendData from "./sendData";
import notifier from "./notifier";

class Coupon {
  constructor(buyForm) {
    this.wrapper = buyForm.querySelector('.coupon-field-wrapper');
    this.field = buyForm.querySelector('input[name=coupon]');
    this.button = buyForm.querySelector('.check-coupon-btn');

    this.price = buyForm.querySelector('.modal-price');
    this.price_discount = buyForm.querySelector('.modal-price-discount');

    this.object_id = buyForm.querySelector('input[name=object_id]').value;
    this.content_type = buyForm.querySelector('input[name=content_type]').value;
  }

  clear_price (){
    this.price.classList.remove('price_type_striked');
    this.price_discount.classList.add('d-none');
    this.price_discount.innerText = '';
  }

  clear (){
    this.field.classList.remove('is-invalid');
    this.field.classList.remove('is-valid');
    this.field.value = '';

    this.button.classList.add('btn-outline-secondary');
    this.button.classList.remove('btn-outline-success');
    this.button.classList.remove('btn-outline-danger');

    this.clear_price();
  }

  validate (code){
    code = code.trim();
    if (code.length > 0){
        let response = sendData('GET',
          Urls['coupons-detail'](code.toUpperCase()),
          {
            'object_id': this.object_id,
            'content_type': this.content_type
          }).then(result => {
            if (result instanceof Array){
              notifier.alert(gettext('Неверный код.') + ' ' + result[0], {durations: {alert: 4000}});
              return null;
            } else {
              notifier.success(gettext('Ваш промокод верен.') + ' '
                  + gettext('Скидка по промокоду:') + ' '
                  + result.value + '%',
                  {durations: {alert: 4000}});
              return result.value;
            }
          }).then((discount) => {
            if (discount === null){
              this.is_invalid();
            } else {
              this.is_valid(discount);
            }
        });
    }
  }

  is_valid (discount){
    this.clear_price();
    this.field.classList.add('is-valid');
    this.field.classList.remove('is-invalid');
    this.button.classList.add('btn-outline-success');
    this.button.classList.remove('btn-outline-danger');
    this.button.classList.remove('btn-outline-secondary');

    const price_value = parseFloat(this.price.querySelector('span[itemprop=price]').innerText);
    const price_currency = this.price_discount.getAttribute('data-currency');
    const price_currency_symbol = this.price_discount.getAttribute('data-currency-symbol');

    this.price.classList.add('price_type_striked');
    this.price_discount.classList.remove('d-none');

    let text = '';
    if (price_currency_symbol){
        text = text + price_currency_symbol;
    }
    text = text + parseFloat((price_value - price_value * discount / 100).toFixed(2)) + ' ';
    if (price_currency_symbol == ''){
        text = text + price_currency + ' ';
    }
    text = text + '(-' + discount + '%)';
    this.price_discount.innerText = text;
  }

  is_invalid (){
    this.clear_price();
    this.field.classList.add('is-invalid');
    this.button.classList.add('btn-outline-danger');
  }

  hide (){
    this.wrapper.classList.add('d-none');
  }

  show (){
    this.wrapper.classList.remove('d-none');
  }

  getList (){
    sendData('GET',
        Urls['coupons-user-available'](),
        {
          'object_id': this.object_id,
          'content_type': this.content_type
        }
    )
    .then((data) => {
      if (data){
        const list = document.createElement('p');
        list.classList.add('small');
        list.classList.add('my-2');
        list.classList.add('coupon-list');
        let items = [];
        const that = this;
        data.forEach(function (result){
          let item = document.createElement('a');
          item.classList.add('mr-2');
          item.setAttribute('href', '#');
          item.setAttribute('id', result.id);
          item.setAttribute('title', gettext('Кликните, чтобы использовать данный код'));
          item.text = result.code;
          item.addEventListener('click', (event) => {
            event.preventDefault();
            that.field.value = result.code;
            that.is_valid(result.value);
          });
          list.insertAdjacentElement('beforeEnd', item);
        });
        this.field.parentNode.insertAdjacentElement(
            'afterEnd', list
        );
      }
    });
  }
}

export default Coupon;


