require('es6-object-assign/auto');
import Cookie from './cookie';
import 'whatwg-fetch';

const csrftoken = new Cookie('csrftoken').get();

function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}

function sameOrigin(url) {
    // test that a given url is a same-origin URL
    // url could be relative or scheme relative or absolute
    var host = document.location.host; // host + port
    var protocol = document.location.protocol;
    var sr_origin = '//' + host;
    var origin = protocol + sr_origin;
    // Allow absolute or scheme relative URLs to same origin
    return (url == origin || url.slice(0, origin.length + 1) == origin + '/') ||
        (url == sr_origin || url.slice(0, sr_origin.length + 1) == sr_origin + '/') ||
        // or any other URL that isn't scheme relative or absolute i.e relative.
        !(/^(\/\/|http:|https:).*/.test(url));
}

function queryParams(params) {
    return Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
}

function serialize(formData){
    /*
    * serialize form
    * */
    const formEntries = formData.entries();
    const jsonData = Object.assign(...Array.from(formEntries, ([x,y]) => ({[x]:y})));
    return jsonData;
}

function sendData(method='GET', url = '', data = {}) {
    let body = data;
    let headers = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        // 'Content-Type': 'application/x-www-form-urlencoded',
    }
    if (!csrfSafeMethod(method) && sameOrigin(url)) {
        // Send the token to same-origin, relative URLs only.
        // Send the token only if the method warrants CSRF protection
        // Using the CSRFToken value acquired earlier
        headers['X-CSRFToken'] = csrftoken;
    }
    if(method == 'GET') {
        if (Object.keys(data).length){
            url += (url.indexOf('?') === -1 ? '?' : '&') + queryParams(data);
        }
        body = null;
    } else if (data instanceof FormData){
        body = JSON.stringify(serialize(data));
    } else {
        body = JSON.stringify(data);
    }

    // Default options are marked with *
    return window.fetch(url, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: 'same-origin', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: headers,
            redirect: 'follow', // manual, *follow, error
            referrer: 'client', // no-referrer, *client
            body: body, // body data type must match "Content-Type" header
        })
        // parses JSON response into native Javascript objects
        .then(response => {
            return response.json();
        })
        .catch(error => {
            console.error(error);
        })
}

export default sendData;
