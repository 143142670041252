import './scss/offers-list.scss';

import './common';
import addToCart from './components/cart.js';
import buyModalForm from "./components/buyModalForm";
import { addFavorite, removeFavorite } from './components/favorite';
import { loadBuyButton } from './components/buyButton';

addToCart();
buyModalForm();
addFavorite();
removeFavorite();
loadBuyButton();
